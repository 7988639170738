import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { FaShippingFast, FaShoppingCart, FaUser } from 'react-icons/fa'

import logo from '../images/logo-white.png'
const Track = () => {
  const router = new URLSearchParams(window.location.search);
  const [orderId, setOrderId] = useState(null);
  const [orderInfo ,setOrderInfo] = useState(null);
  const [openImage,setOpenImage] = useState(null);


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const orderId = queryParams.get('order_id');
    setOrderId(orderId);
  }, []);

 
  useEffect(()=>{

    const link  = window.location.host == 'link.ishtari.com' || window.location.host == 'localhost:3001' ?'https://www.ishtari.com/motor/':'https://www.ishtari.com.gh/'
    console.log(link)
if(orderId){
 
  axios.get(`${link}v2/index.php?route=stockapi/order/getOrdersInfo&order_id=`+orderId).then((res)=>{
    setOrderInfo(res.data.data)
    })
  }
  },[orderId])
  return (
    <div className=' font-poppins text-left flex flex-col h-full  bg-dgreyRate justify-start'>
    
    <div className=' z-50 sticky top-0 flex justify-between px-5 text-center w-full py-4  bg-dbase '>
    <img
                src={logo}
                width={100}
                height={70}
              />
    </div>
{orderInfo &&
<div className='md:container md:mx-auto max-md:px-2 mt-5'>
<h2 className=' text-3xl text-dblackk font-extrabold'>{orderInfo.return_order_id?"Return Details":"Order Details"}</h2>

<div className=' bg-white rounded-md w-full p-3 my-3'>
  <div className=' flex flex-col'>
<h2 className=' text-md font-extrabold'><strong>{orderInfo.return_order_id?`Return_Id: #${orderInfo.return_order_id}`:`Order_Id: #${orderInfo.order_id}`}</strong></h2>
<span className=' text-xs  text-dlabelColor'>{orderInfo.date_printed?orderInfo.date_printed:orderInfo.system_comment}</span>
</div>
</div>
<div className=' bg-white rounded-md w-full p-2 my-3'>
  <div className=' grid   grid-cols-3 max-md:grid-cols-1 gap-5 w-full'>

<div className=' w-full flex flex-row justify-start gap-2'>
<FaUser className=' m-1 text-2xl p-1 rounded-full bg-dplaceHolder'/>
<div className=' flex flex-col tracking-wide  leading-6'>
<h2 className=' text-md font-extrabold flex justify-start gap-2 mb-2'><strong>Customer Info</strong></h2>
 <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Name : </span><strong className=''>{orderInfo.customer_name} </strong></div> 
 <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Phone : </span><strong className=''>{orderInfo.telephone} </strong></div> 
 {orderInfo.shipping_address_1 &&<div className='text-sm flex-col  flex justify-start  gap-2'><span className=' inline-flex  text-dlabelColor'>Address_1: </span><strong className=' text-dblue '>{orderInfo.shipping_address_1} </strong></div> }
 {orderInfo.shipping_address_2 &&<div className='text-sm  flex-col flex justify-start  gap-2'><span className=' inline-flex  text-dlabelColor'>Address_2: </span><strong className=' text-dblue '>{orderInfo.shipping_address_2} </strong></div> }
 <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Zone : </span><strong className=''>{orderInfo.shipping_zone}</strong></div> 
</div>
</div>




{/* <div className='w-full flex flex-row justify-start gap-2'>
    <FaShippingFast className='m-1 text-2xl p-1 rounded-full bg-dplaceHolder'/>
    <div className=' flex flex-col w-fit tracking-wide leading-6'>
<h2 className=' text-md font-extrabold flex justify-start gap-2 mb-2'><strong>Shipping Info</strong></h2>

 <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Logistic : </span><strong className=''>{orderInfo.logistic}</strong></div> 
 <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Track : </span><strong className=''>{orderInfo.track}</strong></div> 
</div>
</div> */}




<div className='w-full flex flex-row justify-start gap-2'>
  <div className=' w-[10%]'>
    <FaShoppingCart className='m-1  text-2xl p-1 rounded-full bg-dplaceHolder'/>
    </div>
    <div className=' flex flex-col tracking-wide leading-6'>
<h2 className=' text-md font-extrabold flex justify-start gap-2 mb-2'><strong>Order Info</strong></h2>
 <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Total : </span><strong className=' text-dbase '>{orderInfo.total < 0 || orderInfo.total == 0 || orderInfo.total == '0'? 0 : orderInfo.total}</strong></div> 
 {/* <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Status : </span><strong className=''>{orderInfo.order_status_name} </strong></div>  */}
 {/* <div className='text-sm  flex justify-start gap-2'><span className='  text-dlabelColor'>Date : </span><strong className=''>{orderInfo.system_comment}</strong></div>  */}
 <div className='text-sm  flex flex-col justify-start mt-1 gap-2'><span className='  text-dlabelColor'>Comment: </span><strong className=' text-sm   text-dblue font-medium'>{orderInfo.comment}</strong></div> 
</div>
</div>
</div>
</div>








{ orderInfo.return_order_id ?
<>{ orderInfo.products.end_products && orderInfo.products.end_products.length > 0 && <div className=' bg-white rounded-md w-full p-3 my-3'>
  <div className=' flex flex-col'>
  <h2 className=' justify-between w-full flex text-md font-extrabold mb-5'><strong>Received Products</strong><strong> استلام المنتجات</strong></h2>

<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-dblackk uppercase bg-dgreyRate ">
            <tr>
                {/* <th scope="col" className="px-6 py-3">
                   Barcode
                </th> */}
                    <th scope="col" className="px-6 py-3">
                   Sku
                </th>
                <th scope="col" className="px-6 py-3">
                    Image
                </th>
                {/* <th scope="col" className="px-6 py-3">
                    Model
                </th> */}
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                {/* <th scope="col" className="px-6 py-3">
                   Order_Product_id
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                   Price
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                   Product Id
                </th> */}
                <th scope="col" className="px-6 py-3">
                   Qty
                </th>
                {/* <th scope="col" className="px-6 py-3">
                   Reason
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                   Seller Id
                </th> */}
            
                {/* <th scope="col" className="px-6 py-3">
                   Total
                </th> */}
            </tr>
        </thead>
        <tbody>
          {orderInfo.products.end_products.map((product, index) => (
            <tr className="bg-white border-b  dark:border-dgrey hover:bg-dgrey dark:hover:bg-gray-200">
                {/* <th scope="row" className="px-6 py-4 font-medium text-dblackk whitespace-nowrap ">
                    {product.barcode}
                </th> */}
                  <td className="px-6 py-4 text-right">
                  {product.sku}
                </td>
                <td  onClick={()=>{setOpenImage(product.image)}} className="px-6   py-4">
                  {/* <img className={`${openImage == product.barcode?"":""} w-full h-full`} src={product.image}/> */}
                  <img className={`w-full  h-full relative  transition-all`} src={product.image}/>
                  
                </td>
                {/* <td className="px-6 py-4">
                   {product.model}
                </td> */}
                <td className="px-6  group py-4">
                  <span className='w-[150px] block overflow-hidden group-hover:whitespace-break-spaces whitespace-nowrap text-ellipsis'>{product.name.replace("&amp;","&")}</span> 
                </td>
                {/* <td className="px-6 py-4 text-right">
                  {product.order_product_id}
                </td> */}
                {/* <td className="px-6 py-4 text-right">
                  {product.price}
                </td> */}
                {/* <td className="px-6 py-4 text-right">
                  {product.product_id}
                </td> */}
                <td className="px-6 py-4 text-right">
                  {product.quantity}
                </td>
                {/* <td className="px-6 py-4 text-right">
                  {product.reason}
                </td> */}
                {/* <td className="px-6 py-4 text-right">
                  {product.seller_id}
                </td> */}
              
                {/* <td className="px-6 py-4 text-dbase font-bold text-right">
                  ${parseFloat(product.total).toFixed(2)}
                </td> */}
            </tr>
            ))}
           
          
       
            
        </tbody>
    </table>
</div>
</div>
</div>
}


{ orderInfo.products.send_products &&  orderInfo.products.send_products.length > 0 && <div className=' bg-white rounded-md w-full p-3 my-3'>
  <div className=' flex flex-col'>
<h2 className=' justify-between w-full flex text-md font-extrabold mb-5'><strong>Send Products</strong><strong> ارسال المنتجات</strong></h2>

<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-dblackk uppercase bg-dgreyRate ">
            <tr>
                {/* <th scope="col" className="px-6 py-3">
                   Barcode
                </th> */}
                    <th scope="col" className="px-6 py-3">
                   Sku
                </th>
                <th scope="col" className="px-6 py-3">
                    Image
                </th>
                {/* <th scope="col" className="px-6 py-3">
                    Model
                </th> */}
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                {/* <th scope="col" className="px-6 py-3">
                   Order_Product_id
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                   Price
                </th> */}
                {/* <th scope="col" className="px-6 py-3">
                   Product Id
                </th> */}
                <th scope="col" className="px-6 py-3">
                   Qty
                </th>
                {/* <th scope="col" className="px-6 py-3">
                   Seller Id
                </th> */}
            
                {/* <th scope="col" className="px-6 py-3">
                   Total
                </th> */}
            </tr>
        </thead>
        <tbody>
          {orderInfo.products.send_products.map((product, index) => (
            <tr className="bg-white border-b  dark:border-dgrey hover:bg-dgrey dark:hover:bg-gray-200">
                {/* <th scope="row" className="px-6 py-4 font-medium text-dblackk whitespace-nowrap ">
                    {product.barcode}
                </th> */}
                  <td className="px-6 py-4 text-right">
                  {product.sku}
                </td>
                <td onClick={()=>{setOpenImage(product.image)}} className="px-6   py-4">
                  {/* <img className={`${openImage == product.barcode?"":""} w-full h-full`} src={product.image}/> */}
                  <img className={`w-full  h-full relative  transition-all`} src={product.image}/>
                  
                </td>
                {/* <td className="px-6 py-4">
                   {product.model}
                </td> */}
                <td className="px-6  group py-4">
                  <span className='w-[150px] block overflow-hidden group-hover:whitespace-break-spaces whitespace-nowrap text-ellipsis'>{product.name.replace("&amp;","&")}</span> 
                </td>
                {/* <td className="px-6 py-4 text-right">
                  {product.order_product_id}
                </td> */}
                {/* <td className="px-6 py-4 text-right">
                  {product.price}
                </td> */}
                {/* <td className="px-6 py-4 text-right">
                  {product.product_id}
                </td> */}
                <td className="px-6 py-4 text-right">
                  {product.quantity}
                </td>
                {/* <td className="px-6 py-4 text-right">
                  {product.seller_id}
                </td> */}
              
                {/* <td className="px-6 py-4 text-dbase font-bold text-right">
                  ${parseFloat(product.total).toFixed(2)}
                </td> */}
            </tr>
            ))}
           
          
       
            
        </tbody>
    </table>
</div>
</div>
</div>
}</>
:
<div className=' bg-white rounded-md w-full p-3 my-3'>
<div className=' flex flex-col'>
<h2 className=' text-md font-extrabold mb-5'><strong>Products</strong></h2>

<div className="relative overflow-x-auto shadow-md sm:rounded-lg">
  <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
      <thead className="text-xs text-dblackk uppercase bg-dgreyRate ">
          <tr>
              {/* <th scope="col" className="px-6 py-3">
                 Barcode
              </th> */}
                  <th scope="col" className="px-6 py-3">
                 Sku
              </th>
              <th scope="col" className="px-6 py-3">
                  Image
              </th>
              {/* <th scope="col" className="px-6 py-3">
                  Model
              </th> */}
              <th scope="col" className="px-6 py-3">
                  Name
              </th>
              {/* <th scope="col" className="px-6 py-3">
                 Order_Product_id
              </th> */}
              {/* <th scope="col" className="px-6 py-3">
                 Price
              </th> */}
              {/* <th scope="col" className="px-6 py-3">
                 Product Id
              </th> */}
              <th scope="col" className="px-6 py-3">
                 Qty
              </th>
              {/* <th scope="col" className="px-6 py-3">
                 Seller Id
              </th> */}
          
              {/* <th scope="col" className="px-6 py-3">
                 Total
              </th> */}
          </tr>
      </thead>
      <tbody>
        {orderInfo.products.map((product, index) => (
          <tr className="bg-white border-b  dark:border-dgrey hover:bg-dgrey dark:hover:bg-gray-200">
              {/* <th scope="row" className="px-6 py-4 font-medium text-dblackk whitespace-nowrap ">
                  {product.barcode}
              </th> */}
                <td className="px-6 py-4 text-right">
                {product.sku}
              </td>
              <td  className="px-6   py-4">
                {/* <img className={`${openImage == product.barcode?"":""} w-full h-full`} src={product.image}/> */}
                <img onClick={()=>{setOpenImage(product.image)}} className={`w-full  h-full relative  transition-all`} src={product.image}/>
                
              </td>
              {/* <td className="px-6 py-4">
                 {product.model}
              </td> */}
              <td className="px-6  group py-4">
                <span className='w-[150px] block overflow-hidden group-hover:whitespace-break-spaces whitespace-nowrap text-ellipsis'>{product.name.replace("&amp;","&")}</span> 
              </td>
              {/* <td className="px-6 py-4 text-right">
                {product.order_product_id}
              </td> */}
              {/* <td className="px-6 py-4 text-right">
                {product.price}
              </td> */}
              {/* <td className="px-6 py-4 text-right">
                {product.product_id}
              </td> */}
              <td className="px-6 py-4 text-right">
                {product.quantity}
              </td>
              {/* <td className="px-6 py-4 text-right">
                {product.seller_id}
              </td> */}
            
              {/* <td className="px-6 py-4 text-dbase font-bold text-right">
                ${parseFloat(product.total).toFixed(2)}
              </td> */}
          </tr>
          ))}
         
        
     
          
      </tbody>
  </table>
</div>
</div>
</div>
}












</div>

}







 <div onClick={()=>setOpenImage(null)} className={` ${openImage?"  scale-100":"  scale-0"} flex  w-full h-full  justify-center text-center fixed bg-dblackOverlay2 `}>
  
  <div onClick={(e)=>e.preventDefault()} className={` relative transition-all  ${openImage?" translate-y-0  duration-300 opacity-100":" -translate-y-96 opacity-0"}  w-[40%]  max-md:w-[80%] flex justify-center text-center md:p-28 my-auto `}>
     <img className='    w-full h-full ' src={openImage}></img>
    </div>

  </div>



    </div>
  )
}

export default Track