import React from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Track from './pages/track';
import Home from './pages/home';
import Layout from './components/layout';

const App = () => {

  return (
    <BrowserRouter>
    <Routes>
   
      <Route path="/" element={<Layout />}/>
      {/* <Route index element={<Home />} /> */}
      <Route path="/track" element={<Track />} />
   
      
    
    </Routes>
  </BrowserRouter>
  )
}

export default App